import React from 'react';
import { Wrapper } from '@/modules/desktop/homepage/components/nft-detail.styled.tsx';
import Tether from '@/assets/graphics/Tether.png';
import Ton from '@/assets/graphics/Ton.png';
import Durian from '@/assets/graphics/Durian.png';
import { useTranslation } from 'react-i18next';
export const NFTDetail = () => {
  const { i18n, t } = useTranslation();
  return (
    <Wrapper className='flex flex-col items-center'>
      <div className='app-container w-full mt-[-20%] ' id='homepage-nft-detail'>
        <div className='w-[160px] hidden max-[475px]:block'>
          <img className='w-full' src={Durian} alt='Durian' />
        </div>
        <div className='font-bold text-[92px] text-[#003C55] max-[1200px]:text-[64px]  max-[600px]:text-[40px]'>
          {t('text-16')}
        </div>

        <div className='grid grid-cols-2 gap-12 bg-[#0D8802] rounded-[64px] p-12 w-full mt-4 relative max-[675px]:p-6 max-[675px]:rounded-[24px] max-[465px]:grid-cols-1 max-[465px]:gap-6'>
          <div className='w-[270px] absolute right-[-30px] top-0  max-[475px]:hidden  -translate-y-2/3 max-[800px]:w-[190px]'>
            <img className='w-full' src={Durian} alt='Durian' />
          </div>
          <div>
            <div className='font-medium text-[20px] max-[675px]:text-[14px]'>{t('text-17')}</div>
            <div className='font-bold text-[64px] max-[1300px]:text-[32px] max-[675px]:text-[24px]'>{t('text-18')}</div>
          </div>
          <div>
            <div className='font-medium text-[20px]  max-[675px]:text-[14px]'>{t('text-19')}</div>
            <div className='flex items-center gap-4'>
              <img className='w-[64px] h-[64px] max-[1300px]:w-[32px] max-[1300px]:h-[32px]' src={Tether} alt='Token' />
              <div className='font-bold text-[64px] max-[675px]:text-[24px] max-[1300px]:text-[32px] '>$ 1,000</div>
            </div>
          </div>
          <div>
            <div className='font-medium text-[20px]  max-[675px]:text-[14px]'>{t('text-20')}</div>
            <div className='flex items-center gap-4'>
              <img className='w-[64px] h-[64px] max-[1300px]:w-[32px] max-[1300px]:h-[32px]' src={Ton} alt='Token' />
              <div className='font-bold text-[64px] max-[675px]:text-[24px] max-[1300px]:text-[32px]'>TON</div>
            </div>
          </div>
          {/* <div>
            <div className='font-medium text-[20px]  max-[675px]:text-[14px]'>Total supply</div>
            <div className='font-bold text-[64px]  max-[675px]:text-[24px] max-[1300px]:text-[32px]'>1,000,000</div>
          </div> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default NFTDetail;
