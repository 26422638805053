import React from 'react';
import { Wrapper } from '@/modules/desktop/homepage/components/partner.styled.tsx';
import Binance from '@/assets/logos/Binance.png';
import Mexc from '@/assets/logos/Mexc.png';
import OPX from '@/assets/logos/OPX.png';
import Bitget from '@/assets/logos/BitGet.png';
import Mountain from '@/assets/background/Mountain.png';
import { useTranslation } from 'react-i18next';

export const Partner = () => {
  const { i18n, t } = useTranslation();
  return (
    <Wrapper className='flex flex-col items-center'>
      <div className='font-bold text-[92px] text-[#003C55] line-height-[128px] mt-32 max-[1200px]:text-[40px] max-[1200px]:leading-[56px]'>
        {t('text-21')}
      </div>
      <div className='app-container grid grid-cols-4 mt-16 mb-16 max-[800px]:grid-cols-2 max-[800px]:gap-2 '>
        <img src={Binance} alt='Binance' />
        <img src={Mexc} alt='Mexc' />
        <img src={OPX} alt='OPX' />
        <img src={Bitget} alt='Bitget' />
      </div>
      <div className='w-full'>
        <img className='w-full' src={Mountain} alt='Mountain' />
      </div>
    </Wrapper>
  );
};

export default Partner;
